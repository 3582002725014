











































import { ref, watch } from '@vue/composition-api';

export default {
  name: 'MConsentTermsPolicy',
  props: {
    terms: {
      type: Boolean,
      default: false
    }
  },
  setup(props: any, context: any) {
    const isChecked = ref(props.terms);

    watch(
      () => props.terms,
      newValue => {
        isChecked.value = newValue;
      }
    );

    return {
      isChecked
    };
  }
};
